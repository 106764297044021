import { useNotification } from "@/composables/notify.js";
import Cookies from "js-cookie";

const checkForUpdates = () => {
  const { notify } = useNotification();
  const currentVersion = Cookies.get("erp_version");
  console.info("Current version:", currentVersion);

  const intervalId = setInterval(() => {
    const newVersion = Cookies.get("erp_version");

    if (newVersion && newVersion !== currentVersion) {
      console.info("New version detected:", newVersion);
      notify({
        title: "Atualização Disponível",
        text: "Uma nova versão está disponível. Por favor, atualize a página para atualizar.",
        type: "info",
        duration: -1,
      });
      clearInterval(intervalId);
    }
  }, 1000);
};

export default checkForUpdates;
